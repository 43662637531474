import React, { useEffect } from 'react'

import Head from 'next/head'
import NextLink from 'next/link'

import {
  AppBar,
  Box,
  Button,
  Divider,
  Hidden,
  Link as MuiLink,
  Toolbar,
  Typography
} from '@material-ui/core'

import { useAuth } from 'use-auth0-hooks'

import { API_ENDPOINT } from '@/common/environment-variables'
import { ReportContent } from '@/components/report-content'
import { RoundedButton } from '@/components/rounded-button'
import { destroyAuthSession } from '@/client/auth'
import { initGA, logPageView } from '@/client/google-analytics'

import styles from './default-layout.module.scss'

export const siteTitle = 'Assurance IQ'

export interface DefaultLayoutProps {
  children: React.ReactNode
  agentId?: number
  loi?: string
  phone?: string
  showMenu?: boolean
  hideAppBar?: boolean
}

export function DefaultLayout(props: DefaultLayoutProps) {
  const { children } = props

  useEffect(() => {
    initGA()
  }, [])

  logPageView()

  return (
    <div className={styles.root}>
      <Head>
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <Header {...props} />
      <main>{children}</main>
      <Footer {...props} />
    </div>
  )
}

function Header(props) {
  const callTextLink =
    props.loi && props.agentId
      ? `${API_ENDPOINT}/amp_ui/cta_call/${props.loi}/${props.agentId}`
      : null
  const emailLink =
    props.loi && props.agentId
      ? `${API_ENDPOINT}/amp_ui/cta_email/${props.loi}/${props.agentId}`
      : null
  const { isAuthenticated, isLoading, logout } = useAuth()

  const doLogout = () => {
    destroyAuthSession()
    logout({ returnTo: window.document.location.origin })
  }

  return (
    <AppBar
      id='appBar' // id='appBar' is used by NProgress to find render target
      position='relative'
      color='default'
      className={styles.appBar}
      style={{
        // inline here to reduce FOUC
        // https://en.wikipedia.org/wiki/Flash_of_unstyled_content
        display: props.hideAppBar ? 'none' : 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fafafa'
      }}
    >
      <NextLink href='/'>
        <a>
          <img src='/images/assurance-logo.svg' alt='Assurance logo' />
        </a>
      </NextLink>
      <Divider orientation='vertical' flexItem />
      <Toolbar className={styles.toolbar}>
        <Box display='flex' flexDirection='row'>
          {props.showMenu && (
            <Hidden xsDown>
              <Button href='#info'>Info</Button>
              <Button href='#insurance'>Insurance</Button>
            </Hidden>
          )}
        </Box>
        <Box display='flex' flexDirection='row'>
          {!isLoading &&
            (isAuthenticated ? (
              <RoundedButton onClick={doLogout} color='secondary' variant='contained'>
                Log out
              </RoundedButton>
            ) : (
              <>
                {props.phone && callTextLink && (
                  <Button className={styles.phone} href={callTextLink}>
                    {props.phone}
                  </Button>
                )}
                {emailLink && (
                  <RoundedButton
                    href={emailLink}
                    color='secondary'
                    variant='contained'
                    disableElevation
                  >
                    Email
                  </RoundedButton>
                )}
              </>
            ))}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

function Footer(props) {
  const [open, setOpen] = React.useState<boolean>(false)
  const logoLink = `${API_ENDPOINT}/amp_ui/utm/root${props.agentId ? '/' + props.agentId : ''}`

  return (
    <>
      <ReportContent open={open} handleClose={() => setOpen(false)} />
      <footer className={styles.footer}>
        <div className={styles.left}>
          <MuiLink href='https://assurance.com/terms-and-conditions' target='_blank' rel='noopener'>
            Terms & Conditions
          </MuiLink>
          <MuiLink href='https://assurance.com/privacy-policy' target='_blank' rel='noopener'>
            Privacy Policy
          </MuiLink>
          <MuiLink href='https://join.assurance.com' target='_blank' rel='noopener'>
            Become an Agent
          </MuiLink>
          <MuiLink onClick={() => setOpen(true)}>Report Inappropriate Content</MuiLink>
        </div>
        <div className={styles.right}>
          <Typography variant='body2' color='textSecondary'>
            Powered by
          </Typography>
          <a href={logoLink} target='_blank' rel='noreferrer'>
            <img src='/images/assurance-logo-name.svg' height={30} alt='Assurance logo' />
          </a>
        </div>
      </footer>
    </>
  )
}
