export enum LOIs {
  medicare = 'medicare',
  health = 'health',
  life = 'life',
  auto = 'auto',
  home = 'home',
  pet = 'pet'
}

export type LOI = keyof typeof LOIs

export enum UsStates {
  AL = 'Alabama',
  AK = 'Alaska',
  AS = 'American Samoa',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District Of Columbia',
  FM = 'Federated States Of Micronesia',
  FL = 'Florida',
  GA = 'Georgia',
  GU = 'Guam',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MH = 'Marshall Islands',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  MP = 'Northern Mariana Islands',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PW = 'Palau',
  PA = 'Pennsylvania',
  PR = 'Puerto Rico',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VI = 'Virgin Islands',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming'
}

export type UsState = keyof typeof UsStates

// ISO639-1 code to human-readable English name
export enum Languages {
  'ab' = 'Abkhazian',
  'aa' = 'Afar',
  'af' = 'Afrikaans',
  'ak' = 'Akan',
  'sq' = 'Albanian',
  'am' = 'Amharic',
  'ar' = 'Arabic',
  'an' = 'Aragonese',
  'hy' = 'Armenian',
  'as' = 'Assamese',
  'av' = 'Avaric',
  'ae' = 'Avestan',
  'ay' = 'Aymara',
  'az' = 'Azerbaijani',
  'bm' = 'Bambara',
  'ba' = 'Bashkir',
  'eu' = 'Basque',
  'be' = 'Belarusian',
  'bn' = 'Bengali',
  'bh' = 'Bihari languages',
  'bi' = 'Bislama',
  'nb' = 'Norwegian Bokmål',
  'bs' = 'Bosnian',
  'br' = 'Breton',
  'bg' = 'Bulgarian',
  'my' = 'Burmese',
  'es' = 'Spanish',
  'ca' = 'Valencian',
  'km' = 'Central Khmer',
  'ch' = 'Chamorro',
  'ce' = 'Chechen',
  'ny' = 'Nyanja',
  'zh' = 'Chinese',
  'za' = 'Zhuang',
  'cu' = 'Old Slavonic',
  'cv' = 'Chuvash',
  'kw' = 'Cornish',
  'co' = 'Corsican',
  'cr' = 'Cree',
  'hr' = 'Croatian',
  'cs' = 'Czech',
  'da' = 'Danish',
  'dv' = 'Maldivian',
  'nl' = 'Flemish',
  'dz' = 'Dzongkha',
  'en' = 'English',
  'eo' = 'Esperanto',
  'et' = 'Estonian',
  'ee' = 'Ewe',
  'fo' = 'Faroese',
  'fj' = 'Fijian',
  'fi' = 'Finnish',
  'fr' = 'French',
  'ff' = 'Fulah',
  'gd' = 'Scottish Gaelic',
  'gl' = 'Galician',
  'lg' = 'Ganda',
  'ka' = 'Georgian',
  'de' = 'German',
  'ki' = 'Kikuyu',
  'el' = 'Greek, Modern (1453-)',
  'kl' = 'Kalaallisut',
  'gn' = 'Guarani',
  'gu' = 'Gujarati',
  'ht' = 'Haitian Creole',
  'ha' = 'Hausa',
  'he' = 'Hebrew',
  'hz' = 'Herero',
  'hi' = 'Hindi',
  'ho' = 'Hiri Motu',
  'hu' = 'Hungarian',
  'is' = 'Icelandic',
  'io' = 'Ido',
  'ig' = 'Igbo',
  'id' = 'Indonesian',
  'ia' = 'Interlingua (International Auxiliary Language Association)',
  'ie' = 'Occidental',
  'iu' = 'Inuktitut',
  'ik' = 'Inupiaq',
  'ga' = 'Irish',
  'it' = 'Italian',
  'ja' = 'Japanese',
  'jv' = 'Javanese',
  'kn' = 'Kannada',
  'kr' = 'Kanuri',
  'ks' = 'Kashmiri',
  'kk' = 'Kazakh',
  'rw' = 'Kinyarwanda',
  'ky' = 'Kyrgyz',
  'kv' = 'Komi',
  'kg' = 'Kongo',
  'ko' = 'Korean',
  'kj' = 'Kwanyama',
  'ku' = 'Kurdish',
  'lo' = 'Lao',
  'la' = 'Latin',
  'lv' = 'Latvian',
  'lb' = 'Luxembourgish',
  'li' = 'Limburgish',
  'ln' = 'Lingala',
  'lt' = 'Lithuanian',
  'lu' = 'Luba-Katanga',
  'mk' = 'Macedonian',
  'mg' = 'Malagasy',
  'ms' = 'Malay',
  'ml' = 'Malayalam',
  'mt' = 'Maltese',
  'gv' = 'Manx',
  'mi' = 'Maori',
  'mr' = 'Marathi',
  'mh' = 'Marshallese',
  'ro' = 'Romanian',
  'mn' = 'Mongolian',
  'na' = 'Nauru',
  'nv' = 'Navajo',
  'nd' = 'North Ndebele',
  'nr' = 'South Ndebele',
  'ng' = 'Ndonga',
  'ne' = 'Nepali',
  'se' = 'Northern Sami',
  'no' = 'Norwegian',
  'nn' = 'Nynorsk, Norwegian',
  'ii' = 'Sichuan Yi',
  'oc' = 'Occitan (post 1500)',
  'oj' = 'Ojibwa',
  'or' = 'Oriya',
  'om' = 'Oromo',
  'os' = 'Ossetic',
  'pi' = 'Pali',
  'pa' = 'Punjabi',
  'ps' = 'Pushto',
  'fa' = 'Persian',
  'pl' = 'Polish',
  'pt' = 'Portuguese',
  'qu' = 'Quechua',
  'rm' = 'Romansh',
  'rn' = 'Rundi',
  'ru' = 'Russian',
  'sm' = 'Samoan',
  'sg' = 'Sango',
  'sa' = 'Sanskrit',
  'sc' = 'Sardinian',
  'sr' = 'Serbian',
  'sn' = 'Shona',
  'sd' = 'Sindhi',
  'si' = 'Sinhalese',
  'sk' = 'Slovak',
  'sl' = 'Slovenian',
  'so' = 'Somali',
  'st' = 'Sotho, Southern',
  'su' = 'Sundanese',
  'sw' = 'Swahili',
  'ss' = 'Swati',
  'sv' = 'Swedish',
  'tl' = 'Tagalog',
  'ty' = 'Tahitian',
  'tg' = 'Tajik',
  'ta' = 'Tamil',
  'tt' = 'Tatar',
  'te' = 'Telugu',
  'th' = 'Thai',
  'bo' = 'Tibetan',
  'ti' = 'Tigrinya',
  'to' = 'Tonga (Tonga Islands)',
  'ts' = 'Tsonga',
  'tn' = 'Tswana',
  'tr' = 'Turkish',
  'tk' = 'Turkmen',
  'tw' = 'Twi',
  'ug' = 'Uyghur',
  'uk' = 'Ukrainian',
  'ur' = 'Urdu',
  'uz' = 'Uzbek',
  've' = 'Venda',
  'vi' = 'Vietnamese',
  'vo' = 'Volapük',
  'wa' = 'Walloon',
  'cy' = 'Welsh',
  'fy' = 'Western Frisian',
  'wo' = 'Wolof',
  'xh' = 'Xhosa',
  'yi' = 'Yiddish',
  'yo' = 'Yoruba',
  'zu' = 'Zulu'
}

export type Language = keyof typeof Languages

export enum AgentLOIs {
  health = 'health',
  life = 'life',
  med_advantage = 'med_advantage',
  medicare = 'medicare',
  auto_home = 'auto_home',
  accidental_death = 'accidental_death'
}

export type AgentLOI = keyof typeof AgentLOIs

export interface Agent {
  assurance_user_id: number
  first_name: string
  last_name: string
  phone_number: string
  email: string
  loi: AgentLOI
  city: string
  state: UsState
  nipr: string
  languages: Language[]
  licensed_states: UsState[]
  resident_state?: {
    state: string
    license_number: string
  }[]
  referral_code: string
  rating: number
  bio?: Bio
  extra?: Extra
}

export interface AgentWithBio extends Agent {
  bio: Bio
}

export interface AgentWithBioExtra extends Agent {
  bio: Bio
  extra: Extra
}

export interface AgentWithExtra extends Agent {
  extra: Extra
}

interface Bio {
  bio: string
  profile_picture?: string
  square_profile_picture?: string
  social_media_profile_picture?: string
  square_profile_picture_crop_info?: string
}

interface Extra {
  profile_enabled: boolean
  location?: Location
}

interface Location {
  name: string
  latitude: number
  longitude: number
  state: string
}

export enum Genders {
  Male = 'male',
  Female = 'female'
}

export type Gender = keyof typeof Genders

export enum Honorifics {
  Mr = 'Mr.',
  Mrs = 'Mrs.',
  Miss = 'Miss.',
  Ms = 'Ms.',
  Dr = 'Dr.'
}

export type Honorific = keyof typeof Honorifics

export enum Degrees {
  'Associate' = 'associate',
  "Bachelor's" = 'bachelor',
  "Master's" = 'masters',
  'M.B.A.' = 'mba',
  'Ph.D' = 'phd',
  'J.D.' = 'jd'
}

export type Degree = keyof typeof Degrees

export enum Sports {
  Baseball = 'baseball',
  Basketball = 'basketball',
  Cricket = 'cricket',
  Football = 'football',
  Lacrosse = 'lacrosse',
  Soccer = 'soccer',
  Tennis = 'tennis',
  Volleyball = 'volleyball',
  Netball = 'netball',
  'Ice Hockey' = 'ice_hockey',
  Hockey = 'hockey'
}

export type Sport = keyof typeof Sports

export enum Hobbies {
  'Watching TV' = 'tv',
  'Reading' = 'reading',
  'Playing Computer Games' = 'games',
  'Playing Music' = 'music_play',
  'Listening to Music' = 'music_listen',
  'Travelling' = 'travelling',
  'Watching Movies' = 'movies',
  'Shopping' = 'shopping'
}

export type Hobby = keyof typeof Hobbies

export enum CommunityServices {
  'Host bake sales' = 'general_1',
  'Help people to register to vote' = 'general_2',
  'Read books and letters to the visually impaired' = 'general_3',
  'Read to residents at a nursing home' = 'elderly_1',
  'Deliver groceries and meals to elderly neighbors' = 'elderly_2',
  'Teach computer skills to the elderly' = 'elderly_3',
  'Drive seniors to doctors appointments' = 'elderly_4',
  'Host holiday meals for senior citizens' = 'elderly_5',
  'Volunteer at an animal shelter' = 'animals_1',
  "Foster animals for which shelters don't have space" = 'animals_2',
  'Grow flowers and give bouquets to hospital patients' = 'flowers_1',
  'Grow flowers and give bouquets to people who are housebound' = 'flowers_2',
  'Create new walking trails at the nature center' = 'nature_1',
  'Maintain walking trails at the nature center' = 'nature_2',
  'Help train service dogs' = 'train_1',
  'Build houses with Habitat for Humanity' = 'homeless_1',
  'Volunteer at a soup kitchen' = 'homeless_2',
  'Make "care kits" to donate to homeless shelters' = 'homeless_3',
  'Take homeless children on outings' = 'homeless_4',
  'Volunteer at the local police station' = 'safety_1',
  'Volunteer at the local firehouse' = 'safety_2',
  'Paint over graffiti in the neighborhood' = 'safety_3',
  'Teach home-alone safety classes for children' = 'safety_4',
  'Paint park benches' = 'enhancement_1',
  'Am a tour guide at the local museum' = 'enhancement_2',
  'Clean up vacant lots' = 'enhancement_3'
}

export type CommunityService = keyof typeof CommunityServices

export interface BioGeneratorForm {
  gender: Gender
  honorific: Honorific
  experience: number
  moved_here: number
  married?: boolean
  married_for?: number
  spouse?: string
  spouse_name?: string
  education?: boolean
  education_1_type?: Degree
  education_1_study?: string
  education_1_school?: string
  education_2_type?: Degree
  education_2_study?: string
  education_2_school?: string
  playing?: boolean
  playing_sport?: Sport[]
  hobbies?: Hobby[]
  hobbies_other?: string
  community_service?: CommunityService[] | null
}

export interface ApiAgentsSearchResults<AgentType> {
  count: number
  results: AgentType[]
}

export enum InappropriateReasons {
  'Inappropriate Bio' = 'bio',
  'Inappropriate Picture' = 'picture',
  'Misleading or Incorrect Information' = 'fake',
  'Other' = 'other'
}

export type InappropriateReason = keyof typeof InappropriateReasons

export interface ApiReportContentRequest {
  reason_code: InappropriateReason
  web_url: string
  note: string
  offender_name: string
}

export interface ApiReportContentResults {}
