import { AgentLOIs } from '@/common/types'

const PRODUCT_NAME_MAP = {
  [AgentLOIs.health]: 'Health',
  [AgentLOIs.life]: 'Life',
  [AgentLOIs.med_advantage]: 'Licensed Insurance',
  [AgentLOIs.medicare]: 'Medicare Supplement',
  [AgentLOIs.auto_home]: 'Auto & Home',
  [AgentLOIs.accidental_death]: 'AD&D'
}

export function getLoiName(loi) {
  return PRODUCT_NAME_MAP[loi]
}

export function getProductNameByLoi(loi): string {
  const loiName = getLoiName(loi)
  const nonInsuranceLOIs = [AgentLOIs.med_advantage, AgentLOIs.medicare, AgentLOIs.accidental_death]
  if (nonInsuranceLOIs.includes(loi)) {
    return loiName
  }
  return loiName + ' Insurance'
}
