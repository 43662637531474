import axios from 'axios'

export function saveAuthSession(auth) {
  const { accessToken, user } = auth
  const username = user.email.split('@').shift()
  sessionStorage.setItem('accessToken', accessToken)
  sessionStorage.setItem('username', username)
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken

  // global response interceptor
  axios.interceptors.response.use(
    function (response) {
      // any 2xx status code causes this function to trigger
      return response
    },
    function (error) {
      // handle any error here
      if (error.message.includes('status code 401')) {
        destroyAuthSession()
        window.alert('Your session has expired! Please log in again.')
        window.location.reload()
      }
      return Promise.reject(error)
    }
  )
}

export function destroyAuthSession() {
  axios.defaults.headers.common['Authorization'] = ''
  sessionStorage.removeItem('accessToken')
  sessionStorage.removeItem('username')
}

export function getUsername() {
  return sessionStorage.getItem('username')
}
