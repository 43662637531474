import _ from 'lodash'
import axios from 'axios'

import { API_ENDPOINT } from '@/common/environment-variables'
import {
  Agent,
  AgentLOI,
  AgentWithBioExtra,
  ApiAgentsSearchResults,
  ApiReportContentRequest,
  ApiReportContentResults,
  BioGeneratorForm,
  UsState
} from './types'

export async function getAgent(username: string): Promise<AgentWithBioExtra> {
  const { data } = await axios.get(`${API_ENDPOINT}/api/v1/agents/${username}`, {
    params: {
      include_bio: true,
      include_extra: true
    }
  })
  return data
}

export async function updateAgent(profile: object, username: string): Promise<Agent> {
  return axios.post(`${API_ENDPOINT}/api/v1/agents/${username}/bio`, profile, {
    headers: {
      'Content-Type': profile['profile_picture'] ? 'multipart/form-data' : 'application/json'
    }
  })
}

interface ListAgentsOptions {
  page: number
  pageSize: number
  includeBio?: boolean
  includeExtra?: boolean
  loi?: AgentLOI
  state?: UsState
}

export async function listAgents<AgentType>(
  listOptions: ListAgentsOptions,
  skipAuth: boolean = false
): Promise<ApiAgentsSearchResults<AgentType>> {
  try {
    const config: { params: object; headers?: Record<string, string | number | boolean> } = {
      params: {
        page: listOptions.page,
        page_size: listOptions.pageSize,
        loi: listOptions.loi,
        state: listOptions.state,
        include_bio: listOptions.includeBio,
        include_extra: listOptions.includeExtra
      }
    }
    if (skipAuth) {
      config.headers = { Authorization: '' }
    }
    const { data } = await axios.get(`${API_ENDPOINT}/api/v1/agents`, config)
    return data
  } catch (err) {
    console.error(err)
    return { count: -1, results: [] }
  }
}

export async function generateBio(formData: BioGeneratorForm): Promise<string> {
  const { data } = await axios.post(`${API_ENDPOINT}/api/v1/generator/bio`, formData)
  return _.trim(data.bio)
}

export async function reportContent(
  formData: ApiReportContentRequest
): Promise<ApiReportContentResults> {
  const { data } = await axios.post(`${API_ENDPOINT}/api/v1/feedback/report_content`, formData)

  return data
}
