import React from 'react'

import { Button } from '@material-ui/core'
import type { ButtonProps } from '@material-ui/core'

import styles from './rounded-button.module.scss'

interface RoundedButtonProps extends ButtonProps {}

export function RoundedButton(props: RoundedButtonProps) {
  return <Button className={styles.root} {...props} />
}
