import ReactGA from 'react-ga'

import { GOOGLE_ANALYTICS_ID } from '@/common/environment-variables'

export function initGA() {
  if (typeof window !== 'undefined' && !window['GA_INITIALIZED']) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    window['GA_INITIALIZED'] = true
  }
}

export function logPageView() {
  if (typeof window !== 'undefined') {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }
}
