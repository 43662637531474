import React, { useRef } from 'react'

import { Alert } from '@material-ui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core'

import _ from 'lodash'

import { InappropriateReason, InappropriateReasons } from '@/common/types'
import { reportContent } from '@/common/api'

import styles from './report-content.module.scss'

interface ReportInappropriateContentDialogProps {
  open: boolean
  handleClose: () => void
}

export function ReportContent(props: ReportInappropriateContentDialogProps) {
  const { open, handleClose } = props
  const [showNackbar, setShowSnackBar] = React.useState<boolean>(false)
  const [reasonError, setReasonError] = React.useState<boolean>(false)
  const [notesError, setNotesError] = React.useState<boolean>(false)
  const [offenderError, setOffenderError] = React.useState<boolean>(false)
  const reasonRef = useRef<HTMLSelectElement>(null)
  const notesRef = useRef<HTMLTextAreaElement>(null)
  const offenderRef = useRef<HTMLInputElement>(null)
  const url = typeof window !== 'undefined' ? window.location.href : null

  async function handleSubmit() {
    if (!reasonRef.current || !notesRef.current || !offenderRef.current) {
      return
    }
    const reason = reasonRef.current.value
    const notes = notesRef.current.value
    const offender = offenderRef.current.value

    setReasonError(_.isEmpty(reason))
    setNotesError(_.isEmpty(notes))
    setOffenderError(_.isEmpty(offender))

    if (_.isEmpty(reason) || _.isEmpty(notes) || _.isEmpty(offender)) {
      return
    }

    await reportContent({
      reason_code: reason as InappropriateReason,
      // @ts-ignore
      web_url: url,
      note: notes,
      offender_name: offender
    })
    handleClose()
    setShowSnackBar(true)
  }

  function clearError(setter) {
    return () => {
      setter(false)
    }
  }

  function closeSnackbar() {
    setShowSnackBar(false)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showNackbar}
        autoHideDuration={5000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity='success'>
          Thank you for reporting offensive content.
        </Alert>
      </Snackbar>

      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Report Inappropriate Content</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='caption' display='block' gutterBottom>
              * All fields are required
            </Typography>
            We’re sorry to expose you to inappropriate content. While some of our content is user
            generated, we will work promptly to ensure the best experience for our customers.
          </DialogContentText>
          <FormControl className={styles.formControl}>
            <InputLabel id='reason-select-label'>Reason</InputLabel>
            <Select
              labelId='reason-select-label'
              inputRef={reasonRef}
              error={reasonError}
              defaultValue=''
              onChange={clearError(setReasonError)}
            >
              {_.keys(InappropriateReasons).map(reason => (
                <MenuItem key={reason} value={InappropriateReasons[reason]}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
            {reasonError && <FormHelperText>Required</FormHelperText>}
          </FormControl>
          <FormControl className={styles.formControl}>
            <TextField
              id='outlined-helperText'
              label='URL'
              defaultValue={url}
              inputProps={{ readOnly: true }}
            />
          </FormControl>
          <FormControl className={styles.formControl}>
            <TextField
              label='Notes'
              placeholder='Please add a note to provide more context to the issue at hand.'
              variant='outlined'
              multiline={true}
              rows={3}
              inputRef={notesRef}
              error={notesError}
              onChange={clearError(setNotesError)}
              helperText={notesError && 'Required'}
            />
          </FormControl>
          <FormControl className={styles.formControl}>
            <TextField
              label='Offender Name'
              placeholder='Offender Name'
              variant='outlined'
              inputRef={offenderRef}
              error={offenderError}
              onChange={clearError(setOffenderError)}
              helperText={offenderError && 'Required'}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Send Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
